import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks/hooks";
import { Login } from "../../redux/actions/auth";
import { Link } from "react-router-dom";
import { brandicaDashboardLocalStorage } from "../../utils/useMultiLocalStorage";

interface AccountFormState {
    email: string;
    password: string;
}

function AccountForm () {
    const theme = useTheme();
    const [formState, setFormState] = useState<AccountFormState>({
        email: '',
        password: '',
    });

    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();

    const handleLogin = () => {
        if (!formState.email || !formState.password) {
            return;
        }

        dispatch(Login(formState.email, formState.password, brandicaDashboardLocalStorage));
    }



    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="start"
            alignItems="center"
            minHeight="50vh"
            flexDirection="column"
            sx={{
                maxWidth: 400,
                padding: "2rem",
            }}
        >
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <Typography
                    sx={{
                        marginBottom: ".5rem",
                    }}
                variant="h6" component="h4" gutterBottom>
                    Login
                </Typography>
                <Typography
                    sx={{
                        fontSize: "12px",
                    }} variant="button" component="p" gutterBottom>
                    Don't have an account?
                </Typography>
                <Typography
                    sx={{
                            fontSize: "10px",
                            marginBottom: ".5rem",
                            textDecoration: "none",
                            
                        }} variant="button" component="p" gutterBottom>
                    <Link style={{color: theme.palette.primary.main, textDecoration: "none"}} to="https://www.gobrandica.com/contact">Contact us to get started!</Link>
                </Typography>
            </Box>

            <TextField
                required
                id="outlined-required"
                label="Email"
                value={formState.email}
                onChange={(e) => setFormState({...formState, email: e.target.value})}
                sx={{
                    marginBottom: ".5rem",
                }}
            />
            
            <TextField
                required
                id="outlined-required"
                label="Password"
                value={formState.password}
                type="password"
                onChange={(e) => setFormState({...formState, password: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                }}
            />
            <Button variant="contained" sx={{
                marginTop: ".5rem",
                width: "100%",
            }}

            onClick={handleLogin}
            >
                {"Login"}
            </Button>
        </Box>
    )

}

export default AccountForm;