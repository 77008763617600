import { TextAssetsState } from "../types/state";
import { CREATE_TEXT_ASSET_FAILURE, CREATE_TEXT_ASSET_START, CREATE_TEXT_ASSET_SUCCESS, DELETE_TEXT_ASSET_FAILURE, DELETE_TEXT_ASSET_START, DELETE_TEXT_ASSET_SUCCESS, FETCH_TEXT_ASSETS_FAILURE, FETCH_TEXT_ASSETS_START, FETCH_TEXT_ASSETS_SUCCESS, SET_SELECTED_STORE } from "../types/stores";

const initialState: TextAssetsState = {
   textAssets: [],
   isLoading: false,
   isCreating: false,
   isDeleting: false,
   error: null,
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_TEXT_ASSETS_START:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case FETCH_TEXT_ASSETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                textAssets: action.payload
            }
        case FETCH_TEXT_ASSETS_FAILURE:
            return {
                ...state,
                isLoading: false,
                textAssets: [],
                error: action.payload
            }
        case CREATE_TEXT_ASSET_START:
            return {
                ...state,
                isCreating: true,
                error: null
            }
        case CREATE_TEXT_ASSET_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload
            }
        case CREATE_TEXT_ASSET_SUCCESS:
            // check for existing text asset
            const existingTextAsset = state.textAssets.find(textAsset => textAsset.sortKey === action.payload.sortKey);
            if (existingTextAsset) {
                // update existing text asset
                const updatedTextAssets = state.textAssets.map(textAsset => {
                    if (textAsset.sortKey === action.payload.sortKey) {
                        return action.payload;
                    }
                    return textAsset;
                });
                return {
                    ...state,
                    isCreating: false,
                    textAssets: updatedTextAssets
                }
            }
            return {
                ...state,
                isCreating: false,
                textAssets: [...state.textAssets, action.payload]
            }
        case DELETE_TEXT_ASSET_START:
            return {
                ...state,
                isDeleting: true,
            }
        case DELETE_TEXT_ASSET_SUCCESS:
            const updatedTextAssets = state.textAssets.filter(textAsset => !textAsset.sortKey.includes(action.payload));
            return {
                ...state,
                isDeleting: false,
                textAssets: updatedTextAssets
            }
        case DELETE_TEXT_ASSET_FAILURE:
            return {
                ...state,
                isDeleting: false,
                error: action.payload
            }

        case SET_SELECTED_STORE:
            return {
                ...state,
                textAssets: []
            };

        default:
            return state;
    }
};

export default auth;