import { StoresState } from "../types/state";
import { CREATE_IMAGE_ASSET_SUCCESS, FETCH_STORES_FAILURE, FETCH_STORES_START, FETCH_STORES_SUCCESS, GET_SIGNED_S3_URL, SET_SELECTED_STORE } from "../types/stores";

const initialState: StoresState = {
   isLoading: false,
   error: null,
   selectedStoreId: null,
   signedUploadUrl: null,
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_STORES_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_STORES_SUCCESS:
            return {
                ...state,
                stores: action.payload,
                isLoading: false,
                error: null,
            };
        case FETCH_STORES_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SET_SELECTED_STORE:
            return {
                ...state,
                selectedStoreId: action.payload,
            };
        case GET_SIGNED_S3_URL:
            return {
                ...state,
                signedUploadUrl: action.payload,
            };
        case CREATE_IMAGE_ASSET_SUCCESS:
            return {
                ...state,
                signedUploadUrl: null,
            }
        default:
            return state;
    }
};

export default auth;