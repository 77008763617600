import React from "react";
import CreateImageForm from "./CreateImageForm";
import { Modal } from "@mui/material";

export default function CreateImageModal ({open, toggleOpen, getS3UploadUrl, createImageAsset}: {
    open: boolean,
    toggleOpen: () => void,
    getS3UploadUrl: (imageId: string, imageType: string) => void,
    createImageAsset: (imageId: string, imageType: string, altText: string, category: string) => void
}) {

    return (
        <Modal
         open={open}
         onClose={toggleOpen}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
         >
            <CreateImageForm getS3UploadUrl={getS3UploadUrl} createImageAsset={createImageAsset}/>
         </Modal>
    )

}