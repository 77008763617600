import { Action, ThunkAction } from "@reduxjs/toolkit";
import { LOGIN_FAILURE, LOGIN_START, LOGIN_SUCCESS, LOGOUT, REGISTER_FAILURE, REGISTER_START, REGISTER_SUCCESS } from "../types/auth";
import { AuthService } from "../../services/AuthService";
import { MultiLocalStorageManager } from "../../utils/useMultiLocalStorage";

export const Login = (email: string, password: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_START});
        const authService = new AuthService(localStorage);
        const response = await authService.login(email, password);
        dispatch({ type: LOGIN_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error });
    }
};

export const Register = (username: string, email: string, password: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_START});
        const authService = new AuthService(localStorage);
        const response = await authService.register(username, email, password);
        dispatch({ type: REGISTER_SUCCESS, payload: response });
    } catch (error) {
        dispatch({ type: REGISTER_FAILURE, payload: error });
    }
};

export const ReLogin = (email: string, tokenExpiresIn: number): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_START});
        dispatch({ type: LOGIN_SUCCESS, payload: {email, tokenExpiresIn} });
    } catch (error) {
        dispatch({ type: LOGIN_FAILURE, payload: error });
    }
};

export const Logout = (localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({ type: LOGOUT});
        const authService = new AuthService(localStorage);
        authService.logout();
    } catch (error) {
        dispatch({ type: LOGOUT, payload: error });
    }
};
