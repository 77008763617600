import React from "react";
import CreateBlogPostForm, { CreateBlogPostFormProps } from "./CreateBlogPostForm";
import { Modal } from "@mui/material";

export interface CreateBlogPostModalProps extends CreateBlogPostFormProps {
    toggleOpen: () => void;
    open: boolean;
}

export default function CreateBlogPostModal ({fetchImages, open, toggleOpen, getS3UploadUrl, createImageAsset, createBlogPost}: CreateBlogPostModalProps) {

    return (
        <Modal
         open={open}
         onClose={toggleOpen}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            overflow: 'auto',
          }}
         >
            <CreateBlogPostForm createBlogPost={createBlogPost} getS3UploadUrl={getS3UploadUrl} createImageAsset={createImageAsset} fetchImages={fetchImages}/>
         </Modal>
    )

}