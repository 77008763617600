import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import { Box } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './redux/store';

function App() {

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Box sx={{ display: 'flex' }}>
          <Dashboard/>
        </Box>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
