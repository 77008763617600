import React from "react";
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import { ExpandMore, FormatBold, FormatBoldOutlined, FormatItalic, FormatItalicOutlined } from "@mui/icons-material";
import { CreateTextAssetInput } from "../../redux/types/stores";

function getFormattedDate(date: Date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
}

export default function TextAssetComponent ({textAssetId, text, tag, color, size, bold, italic, handleEditClick, handleDeleteClick}:
    {
        textAssetId: string,
        text: string,
        tag: string,
        color: string,
        size: string,
        bold: boolean,
        italic: boolean,
        handleEditClick: (textAsset: CreateTextAssetInput) => void
        handleDeleteClick: (textAssetId: string) => void
    }) {

    return (
        <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, width: "30%", maxWidth: '30%', margin: 10}}>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',flexGrow: 1, }}>
                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="panel1a-header"
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography variant="h6" component="h6" style={{ fontWeight: 'bold'}}>
                            {textAssetId}
                            </Typography>

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Typography variant="caption" component="p"
                                style={{ fontWeight: 'bold'}}
                            >
                                Text:
                            </Typography>
                            <Typography variant="body1" component="p">
                                {text}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Size:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {size}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Tag:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {tag}
                                </Typography>
                            </Box>
                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Color:
                                </Typography>
                                <Divider/>
                                <Box
                                    style={{
                                        width: "25px",
                                        height: "25px",
                                        backgroundColor: color
                                    }}
                                >
                            </Box>
                            </Box>
                            <Checkbox checked={bold} icon={<FormatBoldOutlined/>} checkedIcon={<FormatBold/>} />
                            <Checkbox checked={italic} icon={<FormatItalicOutlined/>} checkedIcon={<FormatItalic/>} />
                        </Box>
                    </AccordionDetails>
                    <AccordionActions>
                        <Button onClick={() => handleEditClick({
                            id: textAssetId,
                            text: text,
                            size: size,
                            tag: tag,
                            color: color,
                            bold: bold,
                            italic: italic
                        })} size="small">Edit</Button>
                        <Button onClick={() => handleDeleteClick(textAssetId)} size="small">Delete</Button>
                    </AccordionActions>
                </Accordion>

            </Box>
        </Box>
    )

}