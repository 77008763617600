import { 
    Box, 
    Button, 
    Modal, 
    Typography,
    styled
} from '@mui/material';
import { useState, useRef, useEffect } from 'react';
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ModalContent = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[24],
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    maxWidth: '90vw',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
}));

interface ImageCropperModalProps {
    open: boolean;
    onClose: () => void;
    file: File;
    onCropComplete: (croppedBlob: Blob) => void;
}

export default function ImageCropperModal({ open, onClose, file, onCropComplete }: ImageCropperModalProps) {
    const [crop, setCrop] = useState<Crop>({
        unit: 'px',
        width: 400,
        height: 400,
        x: 0,
        y: 0
    });
    const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
    const imgRef = useRef<HTMLImageElement>(null);

    const [previewUrl, setPreviewUrl] = useState<string>('');
    const previewCanvasRef = useRef<HTMLCanvasElement>(null);

    const createPreviewImage = async (file: File): Promise<string> => {
        const originalUrl = URL.createObjectURL(file);
        const imageType = file.type;
        
        return new Promise((resolve) => {
            const img = new Image();
            
            img.onload = () => {
                const maxDimension = 1200;
                let width = img.width;
                let height = img.height;
                
                if (width > maxDimension || height > maxDimension) {
                    if (width > height) {
                        height = (height / width) * maxDimension;
                        width = maxDimension;
                    } else {
                        width = (width / height) * maxDimension;
                        height = maxDimension;
                    }
                }

                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                
                if (ctx) {
                    ctx.imageSmoothingEnabled = true;
                    ctx.imageSmoothingQuality = 'high';
                    ctx.drawImage(img, 0, 0, width, height);
                    
                    canvas.toBlob((blob) => {
                        URL.revokeObjectURL(originalUrl);
                        if (blob) {
                            const url = URL.createObjectURL(blob);
                            resolve(url);
                        }
                    }, imageType, imageType === 'image/png' ? 1 : 0.85);
                }
            };
            
            img.src = originalUrl;
        });
    };

    useEffect(() => {
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [previewUrl]);

    useEffect(() => {
        if (file) {
            createPreviewImage(file).then(url => {
                setPreviewUrl(url);
            });
        }
    }, [file]);

    const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const { width, height } = e.currentTarget;
        const cropWidth = Math.min(400, width);
        const cropHeight = Math.min(400, height);
        
        const x = (width - cropWidth) / 2;
        const y = (height - cropHeight) / 2;
        
        setCrop({
            unit: 'px',
            width: cropWidth,
            height: cropHeight,
            x,
            y
        });
    };

    const cropImage = async () => {
        if (!completedCrop || !imgRef.current) return;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) return;

        const image = imgRef.current;
        const imageType = file.type;
        
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;

        let finalWidth = completedCrop.width * scaleX;
        let finalHeight = completedCrop.height * scaleY;
        
        if (finalWidth > 800 || finalHeight > 800) {
            const ratio = Math.min(800 / finalWidth, 800 / finalHeight);
            finalWidth *= ratio;
            finalHeight *= ratio;
        }

        canvas.width = finalWidth;
        canvas.height = finalHeight;

        if (imageType === 'image/png') {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            completedCrop.x * scaleX,
            completedCrop.y * scaleY,
            completedCrop.width * scaleX,
            completedCrop.height * scaleY,
            0,
            0,
            finalWidth,
            finalHeight
        );

        canvas.toBlob(
            (blob) => {
                if (blob) {
                    onCropComplete(blob);
                    onClose();
                }
            },
            imageType,
            imageType === 'image/png' ? 1 : 0.92
        );
    };

    return (
        <StyledModal
            open={open}
            onClose={onClose}
        >
            <ModalContent>
                <Typography variant="h6" component="h2">
                    Crop Image
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Select the area you want to use for the product image
                </Typography>
                
                <Box sx={{ 
                    maxWidth: '800px',
                    height: 'auto',
                    margin: '0 auto',
                    flex: '1 1 auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ReactCrop
                        crop={crop}
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                        minWidth={100}
                        minHeight={100}
                    >
                        <img
                            ref={imgRef}
                            src={previewUrl || URL.createObjectURL(file)}
                            onLoad={onImageLoad}
                            style={{ 
                                maxWidth: '100%',
                                maxHeight: '60vh',
                                width: 'auto',
                                height: 'auto',
                                objectFit: 'contain'
                            }}
                        />
                    </ReactCrop>
                </Box>

                <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                    <Button onClick={onClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button 
                        onClick={cropImage} 
                        variant="contained"
                        disabled={!completedCrop}
                    >
                        Crop & Save
                    </Button>
                </Box>
            </ModalContent>
        </StyledModal>
    );
} 