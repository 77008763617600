import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { CreateImageAsset, FetchImageAssets, GetSignedS3Url } from "../redux/actions/stores";
import styled from "@emotion/styled";
import CreateImageModal from "../components/ImageAssets/CreateImageAssetModal";
import ImageAssetComponent from "../components/ImageAssets/ImageAssetComponent";
import { brandicaDashboardLocalStorage } from "../utils/useMultiLocalStorage";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export default function ImageAssetsPage() {
    const [createImageModalOpen, setCreateImageModalOpen] = useState(false);
    const toggleCreateImageModal = () => {
        setCreateImageModalOpen(!createImageModalOpen);
    };

    const { selectedStoreId } = useAppSelector(state => state.storesState);

    const { imageAssets } = useAppSelector(state => state.imageAssetsState);

    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();
    const getS3UploadUrl = (imageId: string, imageType: string) => {
        if(!selectedStoreId) return;
        dispatch(GetSignedS3Url(selectedStoreId, imageId, imageType, brandicaDashboardLocalStorage))
    }

    const createImageAsset = (imageId: string, imageType: string, altText: string, category: string) => {
        if(!selectedStoreId) return;
        dispatch(CreateImageAsset(selectedStoreId, imageId, imageType, altText, category, brandicaDashboardLocalStorage))
        toggleCreateImageModal()
    }

    useEffect(() => {
        if(selectedStoreId) {
            const existingImageAssets = brandicaDashboardLocalStorage.getItem('imageAssets' + selectedStoreId)
            if(imageAssets.length === 0 && selectedStoreId) {
                dispatch(FetchImageAssets(selectedStoreId, existingImageAssets))
            }
        }
    }, [selectedStoreId])

    // save to local storage
    useEffect(() => {
        brandicaDashboardLocalStorage.addUpdateItem('imageAssets' + selectedStoreId, imageAssets)
    }, [imageAssets])

  return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 10, overflowY: 'auto' }}>
            <Typography variant="h4" component="h4">
                Manage Image Assets
            </Typography>
            <Button onClick={toggleCreateImageModal}>Create Image Asset</Button>
            <CreateImageModal open={createImageModalOpen} toggleOpen={toggleCreateImageModal} getS3UploadUrl={getS3UploadUrl} createImageAsset={createImageAsset}/>
            {
                imageAssets.map((asset) => {
                    return (
                        <ImageAssetComponent key={asset.sortKey} url={asset.url} altText={asset.altText} category={asset.category} sortKey={asset.sortKey} createdAt={asset.createdAt || Date.now().toLocaleString()}/>
                    )
                })
            }
        </Box>
    );
}