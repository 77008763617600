import { TextField } from "@mui/material";
import { memo, useEffect, useState } from "react";

interface SearchInputProps {
    initialValue: string;
    onSearch: (query: string) => void;
}

const SearchInput = memo(({ initialValue, onSearch }: SearchInputProps) => {
    const [inputValue, setInputValue] = useState(initialValue);

    useEffect(() => {
        const timeout = setTimeout(() => {
            onSearch(inputValue);
        }, 500);
        
        return () => clearTimeout(timeout);
    }, [inputValue, onSearch]);

    return (
        <TextField
            label="Search"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
        />
    );
});

export default SearchInput; 