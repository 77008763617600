import React, { useEffect } from 'react';
import { styled, Theme } from '@mui/material/styles';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { DarkMode, LightMode } from '@mui/icons-material';
import { useAppSelector } from '../../redux/hooks/hooks';
import { Box, MenuItem, Select, SelectChangeEvent, Switch } from '@mui/material';
import StoreLinkComponent from '../Stores/StoreLinkComponent';

const drawerWidth = 240;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

export default function DashboardHeader({open, toggleOpen, theme, setSelectedStore, darkMode, toggleDarkMode}:
  {
    open: boolean,
    toggleOpen: () => void,
    theme: Theme,
    setSelectedStore: (storeId: string) => void,
    toggleDarkMode: () => void,
    darkMode: boolean,
  }){
  const { stores, selectedStoreId } = useAppSelector(state => state.storesState);

  const { isAuthenticated } = useAppSelector(state => state.authState);

  const store = stores?.find(store => store.storeId === selectedStoreId) ?? stores?.[0];


  useEffect(() => {
    if(stores && stores.length > 0 && !selectedStoreId) {
      setSelectedStore(stores[0].storeId)
    }
  }, [stores])

  const handleChangeStore = (event: SelectChangeEvent) => {
    setSelectedStore(event.target.value);
  };
  return (
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              flexGrow: 1,

            }}
          >
            {
              isAuthenticated && <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleOpen}
              edge="start"
              sx={[
                {
                  marginRight: 5,
                },
                open && { display: 'none' },
              ]}
            >
              <MenuIcon />
            </IconButton>
            }
            
            <Link to="/">
                <img
                    srcSet={`${"https://img-dev.gobrandica.com/Brandica-White.svg"}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    src={`${"https://img-dev.gobrandica.com/Brandica-White.svg"}?w=164&h=164&fit=crop&auto=format`}
                    alt={"Brandica"}
                    loading="lazy"
                    style={{
                        height: '40px',
                        width: 'auto',
                        marginRight: '10px',
                    }}
                />
            </Link>
          </Box>

            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
            >
              {
                stores && stores.length > 0 &&
                <Select
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexGrow: 1,
                    maxHeight: '50px',
                    width: '150px'
                  }}
                  value={store?.storeId}
                  onChange={handleChangeStore}
                >
                  {
                    stores && stores.map(store => {
                      return (
                        <MenuItem key={store.storeId} value={store.storeId}>
                          <StoreLinkComponent key={store.storeId} id={store.storeId} name={store.name} createdAt={store.createdAt} />
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              }
              <Switch checkedIcon={<LightMode/>} icon={<DarkMode/>} value={darkMode} onChange={toggleDarkMode} />
            </Box>

        </Toolbar>
      </AppBar>
  );
}