import { Route, Routes } from "react-router-dom"
import HomePage from "../../Pages/HomePage"
import StoresPage from "../../Pages/StoresPage"
import TextAssetsPage from "../../Pages/TextAssetsPage"
import ImageAssetsPage from "../../Pages/ImageAssetsPage"
import BlogPostsPage from "../../Pages/BlogPostsPage"
import ProtectedRoute from "../Auth/ProtectedRoute"
import AccountPage from "../../Pages/AccountPage"
import ProductsPage from "../../Pages/ProductsPage"

export default function DashboardRouter () {

  return (
    <Routes>
      <Route path="/login" element={<AccountPage />} />
      <Route path="/" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
      <Route path="/stores" element={<ProtectedRoute><StoresPage /></ProtectedRoute>} />
      <Route path="/text-assets" element={<ProtectedRoute><TextAssetsPage /></ProtectedRoute>} />
      <Route path="/image-assets" element={<ProtectedRoute><ImageAssetsPage /></ProtectedRoute>} />
      <Route path="/blog-posts" element={<ProtectedRoute><BlogPostsPage /></ProtectedRoute>} />
      <Route path="/products" element={<ProtectedRoute><ProductsPage /></ProtectedRoute>} />
    </Routes>
  )
}