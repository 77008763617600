export interface MultiLocalStorageManager {
    getState: () => Record<string, any>;
    addUpdateItem: (key: string, value: any) => void;
    getItem: (key: string) => any;
    removeItem: (key: string) => void;
}

export class LocalStorageManager implements MultiLocalStorageManager {
    private storageKey: string;
    private state: Record<string, any>;

    constructor(storageKey: string) {
        this.storageKey = storageKey;
        this.state = this.loadInitialState();
    }

    private loadInitialState(): Record<string, any> {
        const existingState = localStorage.getItem(this.storageKey);
        if (existingState) {
            return JSON.parse(existingState);
        }
        return {};
    }

    private saveState(): void {
        localStorage.setItem(this.storageKey, JSON.stringify(this.state));
    }

    public getState(): Record<string, any> {
        return { ...this.state };
    }

    public addUpdateItem(itemKey: string, value: any): void {
        this.state = {
            ...this.state,
            [itemKey]: value
        };
        this.saveState();
    }

    public removeItem(itemKey: string): void {
        const newState = { ...this.state };
        delete newState[itemKey];
        this.state = newState;
        this.saveState();
    }

    public getItem(itemKey: string): any {
        return this.state[itemKey];
    }
}

// Factory function to create instances
export const createLocalStorage = (key: string): MultiLocalStorageManager => {
    return new LocalStorageManager(key);
};

export const brandicaDashboardLocalStorage = createLocalStorage('brandica-dashboard');