import { Box, Typography, Pagination, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from "@mui/material";
import { useEffect, useState, useCallback, useMemo } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { GetSignedS3Url } from "../redux/actions/stores";
import { brandicaDashboardLocalStorage } from "../utils/useMultiLocalStorage";
import { FetchLocationsProducts, SearchLocationsProducts, UpdateLocationProduct } from "../redux/actions/locations";
import ProductComponent from "../components/Products/ProductComponent";
import SearchInput from "../components/Products/SearchInput";
import { UserCategory } from "../../../BrandicaCore/models/products";

export default function ProductsPage() {
    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();
    const { selectedStoreId, signedUploadUrl } = useAppSelector(state => state.storesState);
    const { locationsProducts, selectedLocationId, totalPages, facets, isLoading, query: currentQuery } = useAppSelector(state => state.locationsState);

    const [debouncedQuery, setDebouncedQuery] = useState(currentQuery || '');
    const [lastEditedTimestamp, setLastEditedTimestamp] = useState<string | null>(
        brandicaDashboardLocalStorage.getItem('lastEditedTimestamp') || null
    );
    const [page, setPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(50);
    const [selectedFacets, setSelectedFacets] = useState<Record<string, string>>({});

    const handleFacetChange = useCallback((fieldName: string, value: string) => {
        setSelectedFacets(prev => ({
            ...prev,
            [fieldName]: value
        }));
        setPage(1);
    }, []);

    const getS3UploadUrl = useCallback((imageId: string, imageType: string) => {
        if(!selectedStoreId || isLoading) return;
        dispatch(GetSignedS3Url(selectedStoreId, imageId, imageType, brandicaDashboardLocalStorage));
    }, [selectedStoreId, isLoading, dispatch]);

    const handleSearch = useCallback((query: string) => {
        setDebouncedQuery(query);
    }, []);

    const handleEditProduct = useCallback(async (productId: string, values: { name: string; userCategory: UserCategory; description?: string; availableOnline?: boolean }) => {
        if (!selectedStoreId || !selectedLocationId || isLoading) return;
        await dispatch(UpdateLocationProduct(selectedStoreId, selectedLocationId, productId, values, brandicaDashboardLocalStorage));
        setLastEditedTimestamp(new Date().getTime().toString());
    }, [selectedStoreId, selectedLocationId, isLoading, dispatch]);

    const handlePageChange = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    }, []);

    const handlePerPageChange = useCallback((event: SelectChangeEvent<number>) => {
        setItemsPerPage(event.target.value as number);
        setPage(1);
    }, []);

    useEffect(() => {
        brandicaDashboardLocalStorage.addUpdateItem('lastEditedTimestamp', lastEditedTimestamp || '');
    }, [lastEditedTimestamp]);

    useEffect(() => {
        if(selectedStoreId && selectedLocationId) {
            const hasSelectedFacets = Object.values(selectedFacets).some(value => value !== '');
            
            if(debouncedQuery || hasSelectedFacets) {
                dispatch(SearchLocationsProducts(
                    selectedStoreId, 
                    selectedLocationId,
                    debouncedQuery,
                    brandicaDashboardLocalStorage,
                    page, 
                    itemsPerPage,
                    selectedFacets
                ));
            } else {
                dispatch(FetchLocationsProducts(
                    selectedStoreId, 
                    selectedLocationId, 
                    brandicaDashboardLocalStorage,
                    page, 
                    itemsPerPage
                ));
            }
        }
    }, [debouncedQuery, selectedStoreId, selectedLocationId, page, itemsPerPage, selectedFacets, dispatch]);

    const renderFacets = useMemo(() => {
        return facets.map((facet) => (
            <FormControl key={facet.field_name} size="small" sx={{ minWidth: 120, mr: 2 }}>
                <InputLabel id={`${facet.field_name}-label`}>
                    {facet.field_name.charAt(0).toUpperCase() + facet.field_name.slice(1)}
                </InputLabel>
                <Select
                    labelId={`${facet.field_name}-label`}
                    value={selectedFacets[facet.field_name] || ''}
                    label={facet.field_name.charAt(0).toUpperCase() + facet.field_name.slice(1)}
                    onChange={(e) => handleFacetChange(facet.field_name, e.target.value)}
                >
                    <MenuItem value="">All</MenuItem>
                    {facet.counts.map((count: any) => (
                        <MenuItem key={count.value} value={count.value}>
                            {count.value} ({count.count})
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        ));
    }, [facets, selectedFacets, handleFacetChange]);

    const memoizedProducts = useMemo(() => 
        locationsProducts.map((product) => (
            <ProductComponent
                key={product.sortKey}
                {...product}
                onEdit={handleEditProduct}
                onImageChange={() => {}}
                getS3UploadUrl={getS3UploadUrl}
                signedUploadUrl={signedUploadUrl}
                selectedStoreId={selectedStoreId || ''}
                selectedLocationId={selectedLocationId || ''}
                localStorage={brandicaDashboardLocalStorage}
            />
        )), 
    [locationsProducts, handleEditProduct, getS3UploadUrl, signedUploadUrl, selectedStoreId, selectedLocationId]);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 10 }}>
            <Typography variant="h4" component="h4">
                Manage Products
            </Typography>
            <SearchInput 
                    initialValue={currentQuery || ''} 
                onSearch={handleSearch}
            />
            <Box sx={{ mt: 3, mb: 2 }}>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                    <InputLabel id="items-per-page-label">Items per page</InputLabel>
                    <Select
                        labelId="items-per-page-label"
                        value={itemsPerPage}
                        label="Items per page"
                        onChange={handlePerPageChange}
                    >
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                    </Select>
                </FormControl>
                {renderFacets}

            </Box>
            <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'flex-start',
                alignItems: 'stretch',
                gap: 2,
            }}>
                {memoizedProducts}
            </Box>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center',
                mt: 4,
                mb: 2
            }}>
                <Pagination 
                    page={page}
                    onChange={handlePageChange}
                    color="primary"
                    showFirstButton 
                    showLastButton
                    count={totalPages || 1}
                />
            </Box>
        </Box>
    );
}