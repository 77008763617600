import { BlogPostsState } from "../types/state";
import { CREATE_BLOG_POST_FAILURE, CREATE_BLOG_POST_START, CREATE_BLOG_POST_SUCCESS, FETCH_BLOG_POSTS_FAILURE, FETCH_BLOG_POSTS_START, FETCH_BLOG_POSTS_SUCCESS, SET_SELECTED_STORE } from "../types/stores";

const initialState: BlogPostsState = {
   blogPosts: [],
   isLoading: false,
   isCreating: false,
   isDeleting: false,
   error: null,
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_BLOG_POST_START:
            return {
                ...state,
                isCreating: true,
                error: null
            }
        case CREATE_BLOG_POST_SUCCESS:
            return {
                ...state,
                isCreating: false,
                blogPosts: [...state.blogPosts, action.payload]
            }
        case CREATE_BLOG_POST_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload
            }
        case FETCH_BLOG_POSTS_START:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case FETCH_BLOG_POSTS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                blogPosts: action.payload
            }
        case FETCH_BLOG_POSTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SET_SELECTED_STORE:
            return {
                ...state,
                blogPosts: []
            };

        default:
            return state;
    }
};

export default auth;