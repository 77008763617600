import { Modal } from "@mui/material";
import EditProductForm, { EditProductFormProps } from "./EditProductForm";

interface EditProductModalProps extends EditProductFormProps {
    open: boolean;
    toggleOpen: () => void;
}

export default function EditProductModal({ open, toggleOpen, ...formProps }: EditProductModalProps) {
    return (
        <Modal
            open={open}
            onClose={toggleOpen}
            aria-labelledby="edit-product-modal"
            aria-describedby="modal-to-edit-product-details"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <EditProductForm {...formProps} />
        </Modal>
    );
} 