import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Checkbox, Divider, FormControlLabel, MenuItem, Select, styled, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { ExpandMore } from "@mui/icons-material";
import BaseEditor from "../Editor/BaseEditor";
import CreateImageForm, {CreateImageFormProps} from "../ImageAssets/CreateImageForm";
import { useAppSelector } from "../../redux/hooks/hooks";
import { BlogPost, BlogPostInput } from "../../redux/types/stores";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Color from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import Image from "@tiptap/extension-image";

interface CreateBlogPostFormState {
    title: string;
    description: string;
    thumbnailImageId: string;
    slug: string;
    published: boolean;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export interface CreateBlogPostFormProps extends CreateImageFormProps {
    fetchImages: () => void;
    createBlogPost: (formState: BlogPostInput) => void;
    existingBlogPost?: BlogPost;
    ref?: React.Ref<any>
}

function CreateBlogPostForm ({fetchImages, getS3UploadUrl, createImageAsset, createBlogPost, existingBlogPost }: CreateBlogPostFormProps) {
    const theme = useTheme();

    const isEditing = !!existingBlogPost;

    // This is thumbnailImage. It is a url
    // https://{{base_url}}/{{store_name}}/{{thumbnailImageId}}
    const [formState, setFormState] = useState<CreateBlogPostFormState>({
        title: existingBlogPost?.title ?? 'Title',
        description: existingBlogPost?.description ?? 'Blog Post Description',
        slug: existingBlogPost?.slug ?? 'blog-post',
        thumbnailImageId: existingBlogPost?.thumbnailImage.split('/').pop() ?? '',
        published: existingBlogPost?.published ?? false,
    });

    const images = useAppSelector(state => state.imageAssetsState.imageAssets)

    useEffect(() => {
        if(!images ||  images.length === 0) {
            fetchImages();
        }
    }, []);

    const editor = useEditor({
        extensions: [
            StarterKit,
            Color.configure({ types: [TextStyle.name, ListItem.name] }),
            TextStyle,
            Image.configure({
                inline: true,
                allowBase64: true,
            })
        ],
        content: existingBlogPost?.content || `
        <p>This is a basic example of implementing images. Drag to re-order.</p>
        <img src="https://placehold.co/600x400" />
        <img src="https://placehold.co/800x400" />
        `
    })

    const handleSubmit = () => {
        const blogPost: BlogPostInput = {
            title: formState.title,
            slug: formState.slug,
            content: JSON.stringify(editor?.getJSON()) || '',
            thumbnailImageId: formState.thumbnailImageId,
            description: formState.description,
            published: formState.published,
        }
        createBlogPost(blogPost);
    }

    return (
        <Box
            display="flex"
            justifyContent="start"
            alignItems="flex-start"
            minHeight="50vh"
            flexDirection="column"
            sx={{
                backgroundColor: theme.palette.background.default,
                padding: "2rem",
                width: "70%",
                overflow: "auto",
                maxHeight: "80vh",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {isEditing ? 'Edit' : 'Create'} Blog Post
            </Typography>
            <Box
                display="flex"
                justifyContent={"space-between"}
                sx={{
                    width: "100%",
                    marginBottom: "1rem",
                }}
            >
                <Box
                    sx={{
                        width: "50%",
                    }}
                >

                    <TextField
                        required
                        id="outlined-required"
                        label="Blog Post Title"
                        value={formState.title}
                        onChange={(e) => setFormState({...formState, title: e.target.value})}
                        sx={{
                            marginBottom: ".5rem",
                            width: "100%",
                        }}
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Blog Post Slug"
                        disabled={isEditing}
                        value={formState.slug}
                        onChange={(e) => setFormState({...formState, slug: e.target.value})}
                        sx={{
                            marginBottom: ".5rem",
                            width: "100%",
                        }}
                    />
                    <TextField
                        required
                        id="outlined-required"
                        label="Blog Post Description"
                        value={formState.description}
                        onChange={(e) => setFormState({...formState, description: e.target.value})}
                        sx={{
                            marginTop: ".5rem",
                            marginBottom: ".5rem",
                            width: "100%",
                        }}
                    />
                    
                    <FormControlLabel control={<Checkbox checked={formState.published} onChange={(e: any) => setFormState({...formState, published: e.target.checked})}/>} label="Publish?" />
                    <Divider sx={{
                        marginTop: ".5rem",
                        marginBottom: ".5rem",
                        width: "100%",
                     }}/>
                    <Typography variant="h6" component="h2" gutterBottom>
                        Thumbnail
                    </Typography>
                    <Typography variant="body2" component="p" gutterBottom>
                        {formState.thumbnailImageId === "" && "Select Existing:"}
                    </Typography>
                    <Select
                        value={formState.thumbnailImageId}
                        onChange={(e) => setFormState({...formState, thumbnailImageId: e.target.value})}
                    >
                        {
                            images.map((image) => (
                                <MenuItem key={image.sortKey} value={image.sortKey.split("#")[1]}>
                                    <Typography variant="body2" component="p" gutterBottom>
                                        {image.sortKey.split("#")[1]}
                                    </Typography>
                                    <img src={image.url.includes("http")? image.url : 'https://' + image.url} alt={image.sortKey.split("#")[1]} width="100px" />
                                </MenuItem>
                            ))
                        }
                    </Select>
                    {
                        formState.thumbnailImageId === "" &&
                        <>
                            <Typography variant="body2" component="p" gutterBottom>
                            Or
                            </Typography>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore/>}
                                >
                                    <Typography>
                                        Create New Image Asset
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <CreateImageForm getS3UploadUrl={getS3UploadUrl} createImageAsset={createImageAsset}/>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    }
                    

                </Box>

            </Box>
            {
                        formState.thumbnailImageId !== "" &&
                        <Button variant="outlined" onClick={() => setFormState({...formState, thumbnailImageId: ""})}>
                            <Typography variant="body2" component="p" gutterBottom>
                                Deselect
                            </Typography>
                        </Button>
                }
            <Divider sx={{
                marginTop: ".5rem",
                marginBottom: ".5rem",
                width: "100%",
            }}/>
            <Typography variant="h6" component="h2" gutterBottom>
                Blog Post Content
            </Typography>
            <Box sx={{ 
                width: '100%',
                '& .ProseMirror': {
                    minHeight: '200px',
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                    padding: 2,
                    overflowX: 'hidden',
                    wordWrap: 'break-word',
                    '&:focus': {
                        outline: 'none',
                        borderColor: theme.palette.primary.main,
                    }
                },
                '& .tiptap': {
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    width: '100%',
                    overflowX: 'hidden'
                },
                '& .tiptap-menubar': {
                    borderBottom: `1px solid ${theme.palette.divider}`,
                    padding: 1,
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        height: 6
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.divider,
                        borderRadius: 3
                    }
                }
            }}>
                {editor && <BaseEditor editor={editor} />}
            </Box>

            <Button 
                variant="contained" 
                sx={{
                    marginTop: ".5rem",
                    width: "100%",
                }}
                onClick={handleSubmit}
            >
                Create Blog Post
            </Button>
        </Box>
    )

}

export default forwardRef((props: CreateBlogPostFormProps, ref) => (
    <CreateBlogPostForm {...props} />
));