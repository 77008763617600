import { Box, Typography } from "@mui/material";

export default function HomePage() {
  return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography sx={{ marginBottom: 2, marginTop: 10}}>
            Under Construction
            </Typography>
            <Typography sx={{ marginBottom: 2 }}>
            Bear with us while we build this out! 🚧
            </Typography>
        </Box>
    );
}