import { LoginResponse } from "../redux/types/auth";
import FetchApi from "./fetchApi";
import { MultiLocalStorageManager } from "../utils/useMultiLocalStorage";

export class AuthService {
    fetchApi: FetchApi
    localStorage: MultiLocalStorageManager
    constructor(localStorage: MultiLocalStorageManager) {
        this.fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api');
        this.localStorage = localStorage;
    }

    async changePassword(email: string, oldPassword: string, newPassword: string): Promise<void> {

    }
    async login(email: string, password: string): Promise<LoginResponse | null> {
        try {
            const response: LoginResponse = await this.fetchApi.post('/login', { email, password });
            // cache token in local storage
            this.localStorage.addUpdateItem('token', response.token);
            this.localStorage.addUpdateItem('email', email);

            const tokenExpiresAt = new Date(Date.now());
            tokenExpiresAt.setSeconds(tokenExpiresAt.getSeconds() + response.tokenExpiresIn);
            this.localStorage.addUpdateItem('tokenExpiresAt', tokenExpiresAt.toISOString());
            this.localStorage.addUpdateItem('tokenExpiresIn', response.tokenExpiresIn.toString());

            return response;
        } catch (error) {
            throw error;
        }
    }
    async register (username: string, email: string, password: string): Promise<LoginResponse | null> {
        try {
            await this.fetchApi.post('/register', { username, email, password });
            const response = await this.login(email, password);
            return response;
        } catch (error) {
            throw error;
        }
    }

    async logout () {
        this.localStorage.removeItem('token')
        this.localStorage.removeItem('tokenExpiresAt')
        this.localStorage.removeItem('tokenExpiresIn')
        this.localStorage.removeItem('email')
    }

    getToken () {
        return this.localStorage.getItem('token');
    }

    checkAuth (): boolean {
        // CHECK IF TOKEN EXISTS AND HASN'T EXPIRED
        // IF IT HASN'T EXPIRED, SET ISAUTHENTICATED TO TRUE
        // IF IT HAS EXPIRED, SET ISAUTHENTICATED TO FALSE AND LOGOUT

        const token = this.localStorage.getItem('token');
        const tokenExpiresAt = this.localStorage.getItem('tokenExpiresAt');

        if (!token || !tokenExpiresAt) {
            return false;
        }
        const expiresAt = new Date(tokenExpiresAt);
        if (expiresAt < new Date()) {
            this.logout();
            return false;
        }
        return true;
    }
}