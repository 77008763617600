import { FormatBold, FormatBoldOutlined, FormatItalic, FormatItalicOutlined } from "@mui/icons-material";
import { Box, Button, Checkbox, MenuItem, Popover, Select, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { CreateTextAssetInput } from "../../redux/types/stores";

function CreateTestAssetForm ({ handleSubmit, existingTextAsset }: {
    handleSubmit: (formData: CreateTextAssetInput) => void,
    existingTextAsset?: CreateTextAssetInput,
    ref?: React.Ref<any>
}) {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const theme = useTheme();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const [formState, setFormState] = useState<CreateTextAssetInput>({
        id: existingTextAsset?.id ?? 'text-asset',
        text: existingTextAsset?.text ?? 'This is the text that is rendered.',
        size: existingTextAsset?.size ??"24px",
        tag: existingTextAsset?.tag ?? 'h2',
        bold: existingTextAsset?.bold ?? false,
        italic: existingTextAsset?.italic ?? false,
        color: existingTextAsset?.color ?? '#000000'
    });

    const isEditing = !!existingTextAsset;

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="start"
            alignItems="center"
            minHeight="50vh"
            flexDirection="column"
            sx={{
                backgroundColor: theme.palette.background.default,
                maxWidth: 400,
                padding: "2rem",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                {isEditing ? "Edit" : "Create"} Text Asset
            </Typography>
            <TextField
                required
                id="outlined-required"
                label="Asset ID"
                disabled={isEditing}
                value={formState.id}
                onChange={(e) => setFormState({...formState, id: e.target.value})}
                sx={{
                    marginBottom: ".5rem",
                    width: "100%",
                }}
            />
            <TextField
                required
                id="outlined-required"
                label="Asset Content"
                value={formState.text}
                onChange={(e) => setFormState({...formState, text: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                    width: "100%",
                }}
            />
            <TextField
                required
                id="outlined-required"
                label="Font Size (px)"
                value={formState.size}
                onChange={(e) => setFormState({...formState, size: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                    width: "100%",
                }}
            />
            <Button
                variant="contained"
                sx={{
                    marginTop: ".5rem",
                    width: "100%",
                }}
                onClick={handleClick}
            >
                Pick a color
            </Button>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <HexColorPicker color={formState.color} onChange={(color) => setFormState({...formState, color: color})} />
            </Popover>
            <HexColorInput style={{
                width: "100%",
                marginTop: ".5rem",
            }} color={formState.color} onChange={(color) => setFormState({...formState, color: color})} />
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    width: "100%"
                }}
            >
                <Select
                    label="Semantics Tag"
                    value={formState.tag}
                    onChange={(e) => setFormState({...formState, tag: e.target.value})}
                    sx={{
                        marginTop: ".5rem",
                        marginBottom: ".5rem",
                    }}
                >
                    <MenuItem value="h1">H1</MenuItem>
                    <MenuItem value="h2">H2</MenuItem>
                    <MenuItem value="h3">H3</MenuItem>
                    <MenuItem value="h4">H4</MenuItem>
                    <MenuItem value="h5">H5</MenuItem>
                    <MenuItem value="h6">H6</MenuItem>
                    <MenuItem value="subtitle1">Subtitle 1</MenuItem>
                    <MenuItem value="subtitle2">Subtitle 2</MenuItem>
                    <MenuItem value="body1">Body 1</MenuItem>
                    <MenuItem value="body2">Body 2</MenuItem>
                    <MenuItem value="inherit">Inherit</MenuItem>
                </Select>
                <Checkbox checked={formState.bold} onChange={(e) => setFormState({...formState, bold: e.target.checked})} icon={<FormatBoldOutlined/>} checkedIcon={<FormatBold/>} />
                <Checkbox checked={formState.italic} onChange={(e) => setFormState({...formState, italic: e.target.checked})} icon={<FormatItalicOutlined/>} checkedIcon={<FormatItalic/>} />
            </Box>
            <Button variant="contained" sx={{
                marginTop: ".5rem",
                width: "100%",
            }}

            onClick={() => {
                handleSubmit(formState);
            }}
            >
                {isEditing ? "Edit" : "Create"} Asset
            </Button>
        </Box>
    )

}

export default forwardRef((props: {handleSubmit: (formData: CreateTextAssetInput) => void,
    existingTextAsset?: CreateTextAssetInput}, ref) => (
    <CreateTestAssetForm {...props} />
));