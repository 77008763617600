import React, { useState } from "react";

import { Box, Button } from "@mui/material";
import CreateStoreModal from "../components/Stores/CreateStoreModal";
import { useAppSelector } from "../redux/hooks/hooks";


export default function StoresPage() {
    const [createStoreModalOpen, setCreateStoreModalOpen] = useState(false);
    const toggleCreateStoreModal = () => {
        setCreateStoreModalOpen(!createStoreModalOpen);
    };

    const { stores, isLoading, error } = useAppSelector(state => state.storesState);


    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 10 }}>
            <Button onClick={toggleCreateStoreModal}>Create Store</Button>
            <CreateStoreModal open={createStoreModalOpen} toggleOpen={toggleCreateStoreModal}/>
            <Box
                component="section"
                sx={{ flexGrow: 1, p: 3, paddingTop: 1 }}
            >
                {/* <Typography variant="h4" component="h4" style={{ fontWeight: 'bold' }}>
                    My Stores
                </Typography>
                <Box component="div" style={{ display: 'flex', flexGrow: 1, flexDirection: 'column', paddingTop: 5 }}>
                    {
                        stores.map(store => {
                            return (
                                <StoreLinkComponent key={store.storeId} id={store.storeId} name={store.name} createdAt={store.createdAt} />
                            )
                        })
                    }
                </Box> */}
            </Box>
        </Box>
    );
}