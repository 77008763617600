import { Action, ThunkAction } from "@reduxjs/toolkit";
import { AuthService } from "../../services/AuthService";
import FetchApi from "../../services/fetchApi";
import { BlogPostInput, CREATE_BLOG_POST_FAILURE, CREATE_BLOG_POST_START, CREATE_BLOG_POST_SUCCESS, CREATE_IMAGE_ASSET_START, CREATE_IMAGE_ASSET_SUCCESS, CREATE_TEXT_ASSET_FAILURE, CREATE_TEXT_ASSET_START, CREATE_TEXT_ASSET_SUCCESS, CreateTextAssetInput, DELETE_TEXT_ASSET_FAILURE, DELETE_TEXT_ASSET_START, DELETE_TEXT_ASSET_SUCCESS, FETCH_BLOG_POSTS_FAILURE, FETCH_BLOG_POSTS_START, FETCH_BLOG_POSTS_SUCCESS, FETCH_IMAGE_ASSETS_FAILURE, FETCH_IMAGE_ASSETS_START, FETCH_IMAGE_ASSETS_SUCCESS, FETCH_STORES_FAILURE, FETCH_STORES_START, FETCH_STORES_SUCCESS, FETCH_TEXT_ASSETS_FAILURE, FETCH_TEXT_ASSETS_START, FETCH_TEXT_ASSETS_SUCCESS, GET_SIGNED_S3_URL, SET_SELECTED_STORE } from "../types/stores";
import { MultiLocalStorageManager } from "../../utils/useMultiLocalStorage";

export const FetchMyStores = (localStorage: MultiLocalStorageManager, existingStores?: any[]): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        if(existingStores) {
            dispatch({ type: FETCH_STORES_SUCCESS, payload: existingStores });
            return;
        }

        console.log('fetching stores')
        dispatch({ type: FETCH_STORES_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();

        console.log({token})
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)

        const response = await fetchApi.get('/stores');
        dispatch({ type: FETCH_STORES_SUCCESS, payload: response.data });
    } catch(err: any) {
        dispatch({type: FETCH_STORES_FAILURE, payload: err.message})
    }
}

export const SetSelectedStore = (storeId: string): ThunkAction<void, any, unknown, Action<string>> => (dispatch) => {
        dispatch({ type: SET_SELECTED_STORE, payload: storeId});
}

export const FetchTextAssets = (storeId: string, localTextAssets?: any[]): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        if(localTextAssets) {
            dispatch({ type: FETCH_TEXT_ASSETS_SUCCESS, payload: localTextAssets });
            return;
        }
        dispatch({ type: FETCH_TEXT_ASSETS_START});
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api')
        const response = await fetchApi.get(`/store/${storeId}/text`);
        dispatch({ type: FETCH_TEXT_ASSETS_SUCCESS, payload: response.assets });
    } catch(err: any) {
        dispatch({type: FETCH_TEXT_ASSETS_FAILURE, payload: err.message})
    }
}

export const CreateTextAsset = (storeId: string, input: CreateTextAssetInput, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({type: CREATE_TEXT_ASSET_START});
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)

        await fetchApi.post(`/store/${storeId}/text`, input);
        dispatch({type: CREATE_TEXT_ASSET_SUCCESS, payload: {...input, sortKey: `TEXT#${input.id}`}})
    } catch(err: any) {
        dispatch({type: CREATE_TEXT_ASSET_FAILURE, payload: err.message})
    }
}

export const DeleteTextAsset = (storeId: string, textAssetId: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({type: DELETE_TEXT_ASSET_START});

        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)

        await fetchApi.delete(`/store/${storeId}/text/${textAssetId}`);
        dispatch({type: DELETE_TEXT_ASSET_SUCCESS, payload: textAssetId})
    }catch(err: any) {
        dispatch({type: DELETE_TEXT_ASSET_FAILURE, payload: err.message})
    }
}

export const GetSignedS3Url = (storeId: string, imageId: string, imageType: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }

        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)

        const response = await fetchApi.get(`/store/${storeId}/image/${imageId}?imageType=${imageType}`);

        const signedUrl = response.signedUrl;

        if(!signedUrl) {
            return;
        }

        dispatch({type: GET_SIGNED_S3_URL, payload: signedUrl})
    } catch (error) {
    }
};

export const FetchImageAssets = (storeId: string, localImageAssets?: any[]): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        if(localImageAssets) {
            dispatch({ type: FETCH_IMAGE_ASSETS_SUCCESS, payload: localImageAssets });
            return;
        }
        dispatch({type: FETCH_IMAGE_ASSETS_START});
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api')
        const response = await fetchApi.get(`/store/${storeId}/image`)
        dispatch({type: FETCH_IMAGE_ASSETS_SUCCESS, payload: response.assets });
    } catch(err: any) {
        dispatch({type: FETCH_IMAGE_ASSETS_FAILURE, payload: err.message})
    }
}

export const CreateImageAsset = (storeId: string, imageId: string, imageType: string, altText: string, category: string, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({type: CREATE_IMAGE_ASSET_START});

        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }

        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const imageData = {
            imageId,
            imageType,
            altText,
            category,
        }
        const res = await fetchApi.post(`/store/${storeId}/image`, imageData);

        dispatch({type: CREATE_IMAGE_ASSET_SUCCESS, payload: res.data.createdAsset})
    } catch (error: any) {
        dispatch({type: CREATE_TEXT_ASSET_FAILURE, payload: error.message})
    }
};

export const FetchBlogPosts = (storeId: string, existingBlogPosts: any[], localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        if(existingBlogPosts) {
            dispatch({ type: FETCH_BLOG_POSTS_SUCCESS, payload: existingBlogPosts });
            return;
        }

        dispatch({type: FETCH_BLOG_POSTS_START})
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }
        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const res = await fetchApi.get(`/store/${storeId}/blog/posts`)
        dispatch({type: FETCH_BLOG_POSTS_SUCCESS, payload: res.assets})
    } catch (error: any) {
        dispatch({type: FETCH_BLOG_POSTS_FAILURE, payload: error.message})
    }
}

export const CreateBlogPost = (storeId: string, blogPost: BlogPostInput, localStorage: MultiLocalStorageManager): ThunkAction<void, any, unknown, Action<string>> => async (dispatch) => {
    try {
        dispatch({type: CREATE_BLOG_POST_START})
        const authService = new AuthService(localStorage);
        const token = authService.getToken();
        if(!token) {
            return;
        }

        const fetchApi = new FetchApi(process.env.REACT_APP_API_URL || 'http://localhost:3000/api', token)
        const res = await fetchApi.post(`/store/${storeId}/blog`, blogPost)
        dispatch({type: CREATE_BLOG_POST_SUCCESS, payload: res.data.createdAsset})
    } catch (error: any) {
        dispatch({type: CREATE_BLOG_POST_FAILURE, payload: error.message})
    }
}