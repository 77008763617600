import React from "react";
import CreateStoreForm from "./CreateStoreForm";
import { Modal } from "@mui/material";

export default function CreateStoreModal ({open, toggleOpen}: {open: boolean, toggleOpen: () => void}) {

    return (
        <Modal
         open={open}
         onClose={toggleOpen}
         aria-labelledby="modal-modal-title"
         aria-describedby="modal-modal-description"
         sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
         >
            <CreateStoreForm/>
         </Modal>
    )

}