import { ImageAssetsState } from "../types/state";
import { CREATE_IMAGE_ASSET_FAILURE, CREATE_IMAGE_ASSET_START, CREATE_IMAGE_ASSET_SUCCESS, FETCH_IMAGE_ASSETS_FAILURE, FETCH_IMAGE_ASSETS_START, FETCH_IMAGE_ASSETS_SUCCESS, SET_SELECTED_STORE } from "../types/stores";

const initialState: ImageAssetsState = {
   imageAssets: [],
   isLoading: false,
   isCreating: false,
   isDeleting: false,
   error: null,
};

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case CREATE_IMAGE_ASSET_START:
        return {
                ...state,
                isCreating: true,
                error: null
            }
        case CREATE_IMAGE_ASSET_SUCCESS:
            return {
                ...state,
                isCreating: false,
                imageAssets: [...state.imageAssets, action.payload]
            }
        case CREATE_IMAGE_ASSET_FAILURE:
            return {
                ...state,
                isCreating: false,
                error: action.payload
            }
        case FETCH_IMAGE_ASSETS_START:
            return {
                ...state,
                isLoading: true,
                error: null
            }
        case FETCH_IMAGE_ASSETS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                imageAssets: action.payload
            }
        case FETCH_IMAGE_ASSETS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            }
        case SET_SELECTED_STORE:
            return {
                ...state,
                imageAssets: []
            };

        default:
            return state;
    }
};

export default auth;