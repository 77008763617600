import { Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useState } from "react";

interface CreateStoreFormState {
    name: string;
    ownerEmail: string;
}

function CreateStoreForm ({ref}: {
    ref: React.Ref<any>
}) {
    const theme = useTheme();
    const [formState, setFormState] = useState<CreateStoreFormState>({
        name: '',
        ownerEmail: '',
    });

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="start"
            alignItems="center"
            minHeight="50vh"
            flexDirection="column"
            sx={{
                backgroundColor: theme.palette.background.default,
                maxWidth: 400,
                padding: "2rem",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Create Store
            </Typography>
            <TextField
                required
                id="outlined-required"
                label="Store Name"
                value={formState.name}
                onChange={(e) => setFormState({...formState, name: e.target.value})}
                sx={{
                    marginBottom: ".5rem",
                }}
            />
            <TextField
                required
                id="outlined-required"
                label="Owners Email"
                value={formState.ownerEmail}
                onChange={(e) => setFormState({...formState, ownerEmail: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                }}
            />
            <Button variant="contained" sx={{
                marginTop: ".5rem",
                width: "100%",
            }}>
                Create Store
            </Button>
        </Box>
    )

}

export default forwardRef((props:any, ref: React.Ref<any>) => (
    <CreateStoreForm ref={ref} />
));