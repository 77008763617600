import { Box, Button, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { CreateTextAsset, DeleteTextAsset, FetchTextAssets } from "../redux/actions/stores";
import CreateTextAssetModal from "../components/TextAssets/CreateTextAssetModal";
import { CreateTextAssetInput } from "../redux/types/stores";
import TextAssetComponent from "../components/TextAssets/TextAssetComponent";
import { brandicaDashboardLocalStorage } from "../utils/useMultiLocalStorage";

export default function TextAssetsPage() {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [textAssetToEdit, setTextAssetToEdit] = useState<CreateTextAssetInput | undefined>(undefined);
    const toggleCreateModal = () => {
        setCreateModalOpen(!createModalOpen);
        textAssetToEdit && setTextAssetToEdit(undefined)
    };

    const { textAssets, isLoading, error } = useAppSelector(state => state.textAssetsState);
    const { selectedStoreId } = useAppSelector(state => state.storesState);
    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();
    useEffect(() => {
        if(selectedStoreId) {
            const existingTextAssets = brandicaDashboardLocalStorage.getItem('textAssets' + selectedStoreId)
            if(textAssets.length === 0 && selectedStoreId) {
                dispatch(FetchTextAssets(selectedStoreId, existingTextAssets))
            }
        }
    }, [selectedStoreId])

    // save to local storage
    useEffect(() => {
        brandicaDashboardLocalStorage.addUpdateItem('textAssets' + selectedStoreId, textAssets)
    }, [textAssets])

    const handleFormSubmit = (formData: CreateTextAssetInput) => {
        if(!selectedStoreId) {
            return
        }
        dispatch(CreateTextAsset(selectedStoreId, formData, brandicaDashboardLocalStorage))
        setCreateModalOpen(false)
    }

    const handleEditClick = (textAsset: CreateTextAssetInput) => {
        setTextAssetToEdit(textAsset)
        setCreateModalOpen(true)
    }

    // TODO: CHANGE THIS TO HAVE A CONFIRMATION
    const handleDeleteClick = (textAssetId: string) => {
        if(!selectedStoreId) {
            return
        }
        dispatch(DeleteTextAsset(selectedStoreId, textAssetId, brandicaDashboardLocalStorage))
    }

  return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 10, overflowY: 'auto' }}>
            <Typography variant="h4" component="h4">
                Manage Text Assets
            </Typography>
            <Button onClick={toggleCreateModal}>Create Text Asset</Button>
            <CreateTextAssetModal existingTextAsset={textAssetToEdit} open={createModalOpen} toggleOpen={toggleCreateModal} handleSubmit={handleFormSubmit}/>
            <Box
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    width: '100%',
                    flexWrap: 'wrap',
                }}
            >
            {
                textAssets.length > 0 && textAssets.map(textAsset => {
                    const textAssetId = textAsset.sortKey.split("#")[1]
                    return (
                        <TextAssetComponent key={textAssetId} handleDeleteClick={handleDeleteClick} handleEditClick={handleEditClick} textAssetId={textAssetId} size={textAsset.size} text={textAsset.text} tag={textAsset.tag} color={textAsset.color} bold={textAsset.bold} italic={textAsset.italic} />
                    )
                })
            }
            </Box>

        </Box>
    );
}