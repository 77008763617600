import React, { useState } from "react";
import CreateTextAssetForm from "./CreateTextAssetForm";
import { Modal } from "@mui/material";
import { CreateTextAssetInput } from "../../redux/types/stores";

export default function CreateTextAssetModal ({open, toggleOpen, handleSubmit, existingTextAsset}:
    {
        open: boolean,
        toggleOpen: () => void,
        handleSubmit: (formData: CreateTextAssetInput) => void,
        existingTextAsset?: CreateTextAssetInput
    }) {

    return (
            <Modal
            open={open}
            onClose={toggleOpen}
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            >
                <CreateTextAssetForm handleSubmit={handleSubmit} existingTextAsset={existingTextAsset}/>
            </Modal>
    )

}