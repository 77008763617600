import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducers from "./reducers";
import { State } from './types/state';
import FetchApi from '../services/fetchApi';

export const store = configureStore({
  reducer: reducers,
});

const baseUrl = process.env.BRANDICA_API_URL || 'http://localhost:3000';

export const fetchApi = new FetchApi(baseUrl);

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  State,
  unknown,
  Action<string>
>;