import { LOGIN_FAILURE, LOGIN_SUCCESS, REGISTER_FAILURE, REGISTER_SUCCESS } from "../types/auth";
import { AuthState } from "../types/state";

const initialState: AuthState = {
    isAuthenticated: false,
    userEmail: null,
    tokenExpiresIn: null,
    error: null,
};


const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                userEmail: action.payload.email,
                tokenExpiresIn: action.payload.tokenExpiresIn,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                userEmail: null,
                tokenExpiresIn: null,
                error: action.payload,
            };
        case REGISTER_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                userEmail: action.payload.email,
                tokenExpiresIn: action.payload.tokenExpiresIn,
            };
        case REGISTER_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                userEmail: null,
                tokenExpiresIn: null,
                error: action.payload,
            };
        case 'LOGOUT':
            return {
                ...state,
                isAuthenticated: false,
                userEmail: null,
                tokenExpiresIn: null,
                error: null,
            };
        default:
            return state;
    }
};

export default auth;