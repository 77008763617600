import React, { forwardRef, useEffect } from "react";
import AccountForm from "../components/Auth/AccountForm";
import { Box } from "@mui/material";
import { useAppSelector } from '../redux/hooks/hooks';
import { useNavigate } from "react-router-dom";

function AccountModal () {

    const { isAuthenticated } = useAppSelector(state => state.authState);

    const navigate = useNavigate()
    
    useEffect(() => {
        if(isAuthenticated) {
            navigate('/products');
        }
    }, [isAuthenticated])

    return (
        <Box
         sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '98vh',
            width: '100%',
            paddingTop: 10
          }}
         >
            <AccountForm />
         </Box>
    )

}

export default AccountModal