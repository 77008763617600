import { LocationsState } from "../types/state";
import { FETCH_LOCATIONS_FAILURE, FETCH_LOCATIONS_PRODUCTS_FAILURE, FETCH_LOCATIONS_PRODUCTS_START, FETCH_LOCATIONS_PRODUCTS_SUCCESS, FETCH_LOCATIONS_START, FETCH_LOCATIONS_SUCCESS, SET_QUERY, SET_SELECTED_LOCATION, UPDATE_LOCATION_PRODUCT_FAILURE, UPDATE_LOCATION_PRODUCT_START, UPDATE_LOCATION_PRODUCT_SUCCESS } from "../types/locations";

const initialState: LocationsState = {
    locations: [],
    locationsProducts: [],
    query: null,
    isLoading: false,
    error: null,
    selectedLocationId: null,
    totalPages: null,
    facets: [],
};  

const auth = (state = initialState, action: any) => {
    switch (action.type) {
        case FETCH_LOCATIONS_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_LOCATIONS_SUCCESS:
            return {
                ...state,
                locations: action.payload,
                isLoading: false,
                error: null,
            };
        case FETCH_LOCATIONS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SET_SELECTED_LOCATION:
            return {
                ...state,
                selectedLocationId: action.payload,
            };
        case FETCH_LOCATIONS_PRODUCTS_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case FETCH_LOCATIONS_PRODUCTS_SUCCESS:
            return {
                ...state,
                locationsProducts: action.payload.products,
                totalPages: action.payload.totalPages,
                facets: action.payload.facets,
                isLoading: false,
                error: null,
            };
        case FETCH_LOCATIONS_PRODUCTS_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };  
        case UPDATE_LOCATION_PRODUCT_START:
            return {
                ...state,
                isLoading: true,
                error: null,
            };
        case UPDATE_LOCATION_PRODUCT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                locationsProducts: state.locationsProducts.map((product) => product.product_id === action.payload.productId ? {...product, ...action.payload } : product),
                error: null,
            };
        case UPDATE_LOCATION_PRODUCT_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.payload,
            };
        case SET_QUERY:
            return {
                ...state,
                query: action.payload,
            };
        default:
            return state;
    }
};

export default auth;