import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CreateBlogPostModal from "../components/BlogPosts/CreateBlogPostModal";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useAppDispatch, useAppSelector } from "../redux/hooks/hooks";
import { CreateBlogPost, CreateImageAsset, FetchBlogPosts, FetchImageAssets, GetSignedS3Url } from "../redux/actions/stores";
import { BlogPostInput } from "../redux/types/stores";
import BlogPostComponent from "../components/BlogPosts/BlogPostComponent";
import { brandicaDashboardLocalStorage } from "../utils/useMultiLocalStorage";

export default function BlogPostsPage() {
    const [createBlogPostModalOpen, setCreateBlogPostModalOpen] = useState(false);
    const toggleCreateBlogPostModal = () => {
        setCreateBlogPostModalOpen(!createBlogPostModalOpen);
    };
    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();
    const { selectedStoreId } = useAppSelector(state => state.storesState);
    const { blogPosts } = useAppSelector(state => state.blogPostsState);

    const fetchImages = () => {
        if(!selectedStoreId) {
            return;
        }
        dispatch(FetchImageAssets(selectedStoreId));
    }

    const createBlogPost = (input: BlogPostInput) => {
        if(!selectedStoreId) {
            return;
        }
        dispatch(CreateBlogPost(selectedStoreId, input, brandicaDashboardLocalStorage));
    }

    const getS3UploadUrl = (imageId: string, imageType: string) => {
        if(!selectedStoreId) return;
        dispatch(GetSignedS3Url(selectedStoreId, imageId, imageType, brandicaDashboardLocalStorage))
    }

    const createImageAsset = (imageId: string, imageType: string, altText: string, category: string) => {
        if(!selectedStoreId) return;
        dispatch(CreateImageAsset(selectedStoreId, imageId, imageType, altText, category, brandicaDashboardLocalStorage))
    }

    useEffect(() => {
        if(selectedStoreId) {
            const existingBlogPosts = brandicaDashboardLocalStorage.getItem('blogPosts' + selectedStoreId)
            if(blogPosts.length === 0 && selectedStoreId) {
                dispatch(FetchBlogPosts(selectedStoreId, existingBlogPosts, brandicaDashboardLocalStorage))
            }
        }
    }, [selectedStoreId])

    // save to local storage
    useEffect(() => {
        brandicaDashboardLocalStorage.addUpdateItem('blogPosts' + selectedStoreId, blogPosts)
    }, [blogPosts])

  return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, paddingTop: 10 }}>
            <Typography variant="h4" component="h4">
                Manage Blog Posts
            </Typography>
            <Button onClick={toggleCreateBlogPostModal}>Create Blog Post</Button>
            <CreateBlogPostModal createBlogPost={createBlogPost} fetchImages={fetchImages} getS3UploadUrl={getS3UploadUrl} createImageAsset={createImageAsset} open={createBlogPostModalOpen} toggleOpen={toggleCreateBlogPostModal}/>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    alignItems: 'start',
                    overflow: 'hidden',
                    bgcolor: 'background.paper',
                }}
            >
            {
                blogPosts.map((blogPost) => (
                    <BlogPostComponent key={blogPost.id} title={blogPost.title} thumbnailImage={blogPost.thumbnailImage} slug={blogPost.slug} published={blogPost.published} description={blogPost.description}/>
                ))
            }
            </Box>
        
        </Box>
    );
}