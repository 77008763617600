import React, { useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { BlogPost } from "../../redux/types/stores";

export default function BlogPostComponent ({
    title,
    description,
    thumbnailImage,
    published,
}: BlogPost) {
    return (
        <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, width: "29%", maxWidth: '29%', margin: 10}}>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',flexGrow: 1, }}>
                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="panel1a-header"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', height: '300px'}}>
                            <img src={thumbnailImage} alt={'Blog Thumbnail Preview'} style={{ maxHeight: '200px', objectFit: 'cover', borderRadius: '5px'}}/>

                            <Typography variant="h6" component="h6" style={{ fontWeight: 'bold', fontSize: '1.2rem', width: '100%' }}>
                                {title}
                            </Typography>

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Typography variant="caption" component="p"
                                style={{ fontWeight: 'bold'}}
                            >
                                Preview:
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Description:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {description}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                   Published:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {published}
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                    {/* <AccordionActions>
                        <Button onClick={() => handleEditClick({
                            id: textAssetId,
                            text: text,
                            size: size,
                            tag: tag,
                            color: color,
                            bold: bold,
                            italic: italic
                        })} size="small">Edit</Button>
                        <Button onClick={() => handleDeleteClick(textAssetId)} size="small">Delete</Button>
                    </AccordionActions> */}
                </Accordion>

            </Box>
        </Box>
    )

}