// import './styles.scss'

import { Code, CodeOutlined, FormatBold, FormatBoldOutlined, FormatItalic, FormatItalicOutlined, FormatListBulleted, FormatListBulletedOutlined, FormatListNumbered, FormatListNumberedOutlined, FormatQuote, FormatQuoteOutlined, FormatStrikethrough, FormatStrikethroughOutlined, HorizontalRule, HorizontalRuleOutlined, ImageOutlined, Redo, Undo, WrapText, WrapTextOutlined } from '@mui/icons-material'
import { Box, Button, Checkbox, MenuItem, Popper, Select, SelectChangeEvent, TextField, Tooltip, useTheme } from '@mui/material'
import { Editor, EditorContent } from '@tiptap/react'
import React from 'react'

const menuItemStyle = {
    padding: 0
}

const MenuBar = ({editor}: {editor: any}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [ imageUrl, setImageUrl ] = React.useState<string>('')
    const [inlineImage,  setInlineImage] = React.useState<boolean>(false)

    const theme = useTheme()
  if (!editor) {
    return null
  }

  const wrapClickPreventDefault = (e: React.MouseEvent<HTMLButtonElement>, fn: () => void) => {
    e.preventDefault()
    fn()
  }

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { value } = e.target

    if(value === 'p') {
        return editor.chain().focus().setParagraph().run()
    } else {
        return editor.chain().focus().toggleHeading({ level: parseInt(value) }).run()
    }
  }

    const insertImage = (url: string) => {
        editor?.chain().focus().insertContent({
            type: 'image',
            attrs: {
                src: url,
                inline: inlineImage
            },
        }).run()

        setImageUrl('')
        setAnchorEl(null)
    }


    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault()
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleTextChange  = (event: React.ChangeEvent<HTMLInputElement>) => {
        setImageUrl(event.target.value);
    };

  return (
      <Box
        sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: ".5rem",
            alignItems: "center",
            width: "100%",
        }}
      >
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: ".5rem",
                width: "70%",
            }}
        >
            <Select
                    value={editor.isActive('heading') ? editor.getAttributes('heading').level : 'p'}
                    disabled={!editor.can().chain().focus().toggleHeading({level: 1}).run()}
                    onChange={handleSelectChange}
                    sx={{
                        marginTop: ".5rem",
                        marginBottom: ".5rem",
                    }}
                >
                <MenuItem sx={menuItemStyle} value="1">H1</MenuItem>
                <MenuItem sx={menuItemStyle} value="2">H2</MenuItem>
                <MenuItem sx={menuItemStyle} value="3">H3</MenuItem>
                <MenuItem sx={menuItemStyle} value="4">H4</MenuItem>
                <MenuItem sx={menuItemStyle} value="5">H5</MenuItem>
                <MenuItem sx={menuItemStyle} value="6">H6</MenuItem>
                <MenuItem sx={menuItemStyle} value="p">Paragraph</MenuItem>
            </Select>
            <Tooltip title="Bold">
                <Checkbox 
                    checked={editor.isActive('bold')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleBold().run())} 
                    icon={<FormatBoldOutlined/>} checkedIcon={<FormatBold/>}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                />
            </Tooltip>
            <Tooltip title="Italic">
                <Checkbox 
                    checked={editor.isActive('italic')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleItalic().run())} 
                    icon={<FormatItalicOutlined/>} checkedIcon={<FormatItalic/>}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                />
            </Tooltip>
            <Tooltip title="Strike">
                <Checkbox 
                    checked={editor.isActive('strike')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleStrike().run())} 
                    icon={<FormatStrikethroughOutlined/>} checkedIcon={<FormatStrikethrough/>}
                    disabled={!editor.can().chain().focus().toggleStrike().run()}
                />
            </Tooltip>
            <Tooltip title="Code">
                <Checkbox 
                    checked={editor.isActive('code')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleCode().run())} 
                    icon={<CodeOutlined/>} checkedIcon={<Code/>}
                    disabled={!editor.can().chain().focus().toggleCode().run()}
                />
            </Tooltip>

            <Tooltip title="Bullet List">
                <Checkbox 
                    checked={editor.isActive('bulletList')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleBulletList().run())} 
                    icon={<FormatListBulletedOutlined/>} checkedIcon={<FormatListBulleted/>}
                    disabled={!editor.can().chain().focus().toggleBulletList().run()}
                />
            </Tooltip>

            <Tooltip title="Number List">
                <Checkbox 
                    checked={editor.isActive('orderedList')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleOrderedList().run())} 
                    icon={<FormatListNumberedOutlined/>} checkedIcon={<FormatListNumbered/>}
                    disabled={!editor.can().chain().focus().toggleOrderedList().run()}
                />
            </Tooltip> 
            <Tooltip title="Block Quote">
                <Checkbox 
                    checked={editor.isActive('blockquote')} 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().toggleBlockquote().run())} 
                    icon={<FormatQuoteOutlined/>} checkedIcon={<FormatQuote/>}
                    disabled={!editor.can().chain().focus().toggleBlockquote().run()}
                />     
            </Tooltip> 
            <Tooltip title="Horizontal Rule">
                <Checkbox 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().setHorizontalRule().run())} 
                    icon={<HorizontalRuleOutlined/>}
                    disabled={!editor.can().chain().focus().setHorizontalRule().run()}
                />       
            </Tooltip>
            <Tooltip title="Insert Image">
                <Checkbox 
                    onClick={handleClick} 
                    icon={<ImageOutlined/>}
                />       
            </Tooltip>
            <Popper sx={{
                zIndex: 9999,
                display: "flex",
                flexDirection: "row",
                backgroundColor: theme.palette.background.default,
            }} open={open} anchorEl={anchorEl}>
                <TextField value={imageUrl} onChange={handleTextChange}/>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Checkbox checked={inlineImage} icon={<WrapTextOutlined/>} checkedIcon={<WrapText/>} onChange={() => setInlineImage(!inlineImage)}/>
                    <Button onClick={() => insertImage(imageUrl)}>Add</Button>
                </Box>
            </Popper>
        </Box>
               
        <Box
            sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: ".5rem",
                width: "10%",
            }}
        >
            <Tooltip title="Undo">
                <Checkbox 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().undo().run())} 
                    icon={<Undo/>}
                    disabled={!editor.can().chain().focus().undo().run()}
                />     
            </Tooltip>
            <Tooltip title="Redo">    
                <Checkbox 
                    onClick={e => wrapClickPreventDefault(e, () => editor.chain().focus().redo().run())} 
                    icon={<Redo/>}
                    disabled={!editor.can().chain().focus().redo().run()}
                />
            </Tooltip> 
        </Box>

      </Box>
  )
}

export default ({editor}: {editor: Editor}) => {


    



  return (
    <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
        overflow={'auto'}
        sx={{
            overflowX: 'hidden',
        }}
    >
        <MenuBar editor={editor} />
        <Box
            width="100%"
            maxHeight={'60vh'}
            overflow={'auto'}
        >
            <EditorContent editor={editor} />
        </Box>
    </Box>
  )
}