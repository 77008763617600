import React from "react";
import { Box, Typography } from "@mui/material";

function getFormattedDate(date: Date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');

    return month + '/' + day + '/' + year;
}

export default function StoreLinkComponent ({id, name, createdAt}: {id: string, name: string, createdAt: string}) {

    return (
        <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, maxHeight: '50%' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', flexGrow: 1, paddingLeft: 5, maxWidth: '150px' }}>
                <Typography variant="h6" component="h6" style={{ fontWeight: 'bold', fontSize: '12px' }}>
                    {name}
                </Typography>
            </Box>
        </Box>
    )

}