import React, { useEffect, useState } from 'react';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import DashboardMenu from './Menu';
import DashboardHeader from './Header';
import DashboardRouter from './Router';
import { useAppDispatch, useAppSelector } from '../../redux/hooks/hooks';
import { AuthService } from '../../services/AuthService';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { ReLogin } from '../../redux/actions/auth';
import { FetchMyStores } from '../../redux/actions/stores';
import { SET_SELECTED_STORE } from '../../redux/types/stores';
import { FetchMyLocations } from '../../redux/actions/locations';
import { brandicaDashboardLocalStorage } from '../../utils/useMultiLocalStorage';

export default function Dashboard() {

    const [darkMode, setDarkMode] = useState(brandicaDashboardLocalStorage.getItem('darkMode') === 'true');

    const toggleDarkMode = () => {
        brandicaDashboardLocalStorage.addUpdateItem('darkMode', (!darkMode).toString());
        setDarkMode(!darkMode);
    };

    const theme = createTheme({
        palette: {
            mode: darkMode ? 'dark' : 'light',
          },
    });

    const { stores, selectedStoreId, isLoading, error } = useAppSelector(state => state.storesState);
    const { isAuthenticated } = useAppSelector(state => state.authState);
    const [open, setOpen] = useState(false);
    const [accountModalOpen, setAccountModalOpen] = useState(false);
    const dispatch: ThunkDispatch<any, any, any> = useAppDispatch();

    const authService = new AuthService(brandicaDashboardLocalStorage);

    useEffect(() => {
        if(stores) {
            brandicaDashboardLocalStorage.addUpdateItem('stores', stores)
        }
    }, [stores])

    useEffect(() => {
        const stores = brandicaDashboardLocalStorage.getItem('stores')
        if(isAuthenticated && !stores && !isLoading && error === null) {
            dispatch(FetchMyStores(brandicaDashboardLocalStorage))
        }

        if(isAuthenticated && stores?.length > 0 && !isLoading && error === null) {
            dispatch(FetchMyStores(brandicaDashboardLocalStorage, stores))
        }
    }, [isAuthenticated, stores?.length, isLoading, error]);

    useEffect(() => {
        if(selectedStoreId) {
            dispatch(FetchMyLocations(selectedStoreId, brandicaDashboardLocalStorage));
        }
    }, [selectedStoreId]);

    const toggleOpen = () => {
        setOpen(!open);
    };

    const setSelectedStore = (storeId: string) => {
        dispatch({ type: SET_SELECTED_STORE, payload: storeId });
    };

    useEffect(() => {
        if(!isAuthenticated) {
            if(authService.checkAuth()) {
                const email = brandicaDashboardLocalStorage.getItem('email');
                if(!email) {
                    authService.logout();
                    return;
                }
                const tokenExpiresIn = brandicaDashboardLocalStorage.getItem('tokenExpiresIn');
                dispatch(ReLogin(email, Number(tokenExpiresIn)));
            }
        }
    }, [])
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>

            <DashboardHeader open={open} toggleOpen={toggleOpen} theme={theme} setSelectedStore={setSelectedStore} darkMode={darkMode} toggleDarkMode={toggleDarkMode}/>

            {
                isAuthenticated && <DashboardMenu open={open} toggleOpen={toggleOpen} theme={theme}/>
            }

            {/* will change to the page that will be displayed */}
            <DashboardRouter />
        </ThemeProvider>
    );
}