export const FETCH_STORES_START = "FETCH_STORES_START";
export const FETCH_STORES_SUCCESS = "FETCH_STORES_SUCCESS";
export const FETCH_STORES_FAILURE = "FETCH_STORES_FAILURE";

export const FETCH_TEXT_ASSETS_START = "FETCH_TEXT_ASSETS_START";
export const FETCH_TEXT_ASSETS_SUCCESS = "FETCH_TEXT_ASSETS_SUCCESS";
export const FETCH_TEXT_ASSETS_FAILURE = "FETCH_TEXT_ASSETS_FAILURE";

export const CREATE_TEXT_ASSET_START = "CREATE_TEXT_ASSET_START";
export const CREATE_TEXT_ASSET_SUCCESS = "CREATE_TEXT_ASSET_SUCCESS";
export const CREATE_TEXT_ASSET_FAILURE = "CREATE_TEXT_ASSET_FAILURE";

export const DELETE_TEXT_ASSET_START = "DELETE_TEXT_ASSET_START";
export const DELETE_TEXT_ASSET_SUCCESS = "DELETE_TEXT_ASSET_SUCCESS";
export const DELETE_TEXT_ASSET_FAILURE = "DELETE_TEXT_ASSET_FAILURE";

export const FETCH_IMAGE_ASSETS_START = "FETCH_IMAGE_ASSETS_START";
export const FETCH_IMAGE_ASSETS_SUCCESS = "FETCH_IMAGE_ASSETS_SUCCESS";
export const FETCH_IMAGE_ASSETS_FAILURE = "FETCH_IMAGE_ASSETS_FAILURE";

export const CREATE_IMAGE_ASSET_START = "CREATE_IMAGE_ASSET_START";
export const CREATE_IMAGE_ASSET_SUCCESS = "CREATE_IMAGE_ASSET_SUCCESS";
export const CREATE_IMAGE_ASSET_FAILURE = "CREATE_IMAGE_ASSET_FAILURE";

export const FETCH_BLOG_POSTS_START = "FETCH_BLOG_POSTS_START";
export const FETCH_BLOG_POSTS_SUCCESS = "FETCH_BLOG_POSTS_SUCCESS";
export const FETCH_BLOG_POSTS_FAILURE = "FETCH_BLOG_POSTS_FAILURE";

export const CREATE_BLOG_POST_START = "CREATE_BLOG_POST_START";
export const CREATE_BLOG_POST_SUCCESS = "CREATE_BLOG_POST_SUCCESS";
export const CREATE_BLOG_POST_FAILURE = "CREATE_BLOG_POST_FAILURE";

export const SET_SELECTED_STORE = "SET_SELECTED_STORE";

export const GET_SIGNED_S3_URL = "GET_SIGNED_S3_URL";

export interface CreateTextAssetInput {
    id: string;
    text: string;
    size: string;
    tag: string;
    bold: boolean;
    italic: boolean;
    color: string;
}

// "title": "title",
// "slug": "slug",
// "content": "content",
// "thumbnailImageId": "thumbnailImageId",
// "description": "description",
// "published": false
export interface BlogPostInput {
    title: string;
    slug: string;
    content: string;
    thumbnailImageId: string;
    description: string;
    published: boolean;
}

export interface BlogPost {
    title: string;
    slug: string;
    content?: string;
    thumbnailImage: string;
    description: string;
    published: boolean;
}