import { CloudUpload } from "@mui/icons-material";
import { Box, Button, styled, TextField, Typography, useTheme } from "@mui/material";
import { forwardRef, useEffect, useState } from "react";
import { useAppSelector } from "../../redux/hooks/hooks";

interface CreateStoreFormState {
    id: string;
    altTag: string;
    category: string;
    image: File | null;
}

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

export interface CreateImageFormProps {
    getS3UploadUrl: (imageId: string, imageType: string) => void;
    createImageAsset: (imageId: string, imageType: string, altText: string, category: string) => void;
    ref?: React.Ref<any>
}

function CreateImageForm ({getS3UploadUrl, createImageAsset}: CreateImageFormProps) {
    const theme = useTheme();
    const [formState, setFormState] = useState<CreateStoreFormState>({
        id: '',
        altTag: '',
        image: null,
        category: '',
    });

    const [ shouldCreatePost, setShouldCreatePost ] = useState(false)

    const { signedUploadUrl } = useAppSelector(state => state.storesState)

    const handleImageCreate = () => {
        if(!formState.image || !formState.id || !formState.altTag) return;
        setShouldCreatePost(true)
    }

    useEffect(() => {
        if(!formState.image || !formState.id || !formState.altTag) return;
        if(shouldCreatePost && formState.image?.type) {
            getS3UploadUrl(formState.id, formState.image?.type)
        }
    }, [shouldCreatePost])

    useEffect(() => {
        if(!formState.image || !formState.id || !formState.altTag || !formState.category) return;
        if(signedUploadUrl && signedUploadUrl !== '') {
            uploadImage()
                .then(() => {
                    if(!formState.image) return;

                    let imageType = formState.image?.type.split('/')[1]
                    if(imageType.indexOf("svg") !== -1) {
                        imageType = 'svg'
                    }

                    createImageAsset(formState.id, formState.image?.type.split('/')[1], formState.altTag, formState.category)
                    setShouldCreatePost(false)
                    setFormState({
                        id: '',
                        altTag: '',
                        image: null,
                        category: '',
                    })
                })
        }
    }, [signedUploadUrl])


    const uploadImage = async () => {
        if(!signedUploadUrl || signedUploadUrl === '' ||!formState.image) return;
        await fetch(signedUploadUrl, {
            method: 'PUT',
            body: formState.image,
        })
    }

    return (
        <Box
            component="form"
            noValidate
            autoComplete="off"
            display="flex"
            justifyContent="start"
            alignItems="center"
            minHeight="50vh"
            flexDirection="column"
            sx={{
                backgroundColor: theme.palette.background.default,
                maxWidth: 400,
                padding: "2rem",
            }}
        >
            <Typography variant="h4" component="h1" gutterBottom>
                Create Image Asset
            </Typography>
            <TextField
                required
                id="outlined-required"
                label="Image Asset ID"
                value={formState.id}
                onChange={(e) => setFormState({...formState, id: e.target.value})}
                sx={{
                    marginBottom: ".5rem",
                    width: "100%"
                }}
            />
            <TextField
                required
                id="outlined-required"
                label="Image Alt Tag"
                value={formState.altTag}
                onChange={(e) => setFormState({...formState, altTag: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                    width: "100%"
                }}
            />
            <TextField
                required
                id="outlined-required"
                label="Image Category"
                value={formState.category}
                onChange={(e) => setFormState({...formState, category: e.target.value})}
                sx={{
                    marginTop: ".5rem",
                    marginBottom: ".5rem",
                    width: "100%"
                }}
            />
            <img
             src={formState.image ? URL.createObjectURL(formState.image) : ""}
             style={{
                maxWidth: "100%",
             }}
             />
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
                sx={{
                    width: "100%"
                }}
                >
                Choose Image
                <VisuallyHiddenInput
                    type="file"
                    onChange={(event) => setFormState({...formState, image: event.target.files![0]})}
                    accept="image/png, image/jpeg, image/svg+xml, image/webp"
                />
            </Button>
            <Button variant="contained" sx={{
                marginTop: ".5rem",
                width: "100%",
            }}

            onClick={handleImageCreate}
            >
                Upload Image Asset
            </Button>
        </Box>
    )

}

export default forwardRef((props: CreateImageFormProps, ref) => (
    <CreateImageForm {...props} />
));