import React from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

export default function ImageAssetComponent ({url, altText, sortKey, category, createdAt}:
    {
        url: string
        sortKey: string
        altText: string
        category: string
        createdAt: string
    }) {

    return (
        <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, width: "30%", maxWidth: '20%', margin: 10}}>
            <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center',flexGrow: 1, }}>
                <Accordion style={{ width: '100%' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMore/>}
                        id="panel1a-header"
                    >
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Typography variant="h6" component="h6" style={{ fontWeight: 'bold'}}>
                            {sortKey.split("#")[1]}
                            </Typography>

                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                            <Typography variant="caption" component="p"
                                style={{ fontWeight: 'bold'}}
                            >
                                Preview:
                            </Typography>
                            <img src={url} alt={altText} style={{ width: '100%' }}/>
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Category:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {category}
                                </Typography>
                            </Box>

                            <Box
                                style={{
                                    marginLeft: 5,
                                    marginRight: 5,
                                }}
                            >
                                <Typography variant="caption" component="p"
                                    style={{ fontWeight: 'bold'}}
                                >
                                    Alt Text:
                                </Typography>
                                <Divider/>
                                <Typography variant="subtitle2" component="p">
                                    {altText}
                                </Typography>
                            </Box>
                        </Box>
                    </AccordionDetails>
                    {/* <AccordionActions>
                        <Button onClick={() => handleEditClick({
                            id: textAssetId,
                            text: text,
                            size: size,
                            tag: tag,
                            color: color,
                            bold: bold,
                            italic: italic
                        })} size="small">Edit</Button>
                        <Button onClick={() => handleDeleteClick(textAssetId)} size="small">Delete</Button>
                    </AccordionActions> */}
                </Accordion>

            </Box>
        </Box>
    )

}